/*=====================
    onboarding Page CSS
==========================*/
.home {
    position: relative;

    .intro-video {
        position: fixed;
        top: -10%;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        max-width: 767px;
        width: 100vw;
        height: 100vh;
        z-index: -1;
        -o-object-fit: cover;
            object-fit: cover;
    }

    .theme-content {
        position: fixed;
        bottom: 0;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        width: 100%;
        max-width: 767px;
        padding: 20px 0;
        background: -webkit-gradient(linear, left bottom, left top, color-stop(57.48%, rgba(0, 0, 0, 0.89)), to(rgba(0, 0, 0, 0)));
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.89) 57.48%, rgba(0, 0, 0, 0) 100%);
        -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }

    .slogan {
        margin-top: 20px;
        padding: 12px 0;
        border-top: 1px solid rgba(225, 225, 225, 0.12);
        border-bottom: 1px solid rgba(225, 225, 225, 0.12);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;

        li {
            color: rgb(225, 225, 225);
            font-size: 20px;
            font-weight: 600;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            gap: calc(10px + 10 * (100vw - 320px) / 1600);
        }
    }

    p {
        margin-top: 15px;
        font-size: 20px;
        font-weight: 600;
        color: rgba(225, 225, 225, 1);
        padding: 0;
    }

    .start-btn {
        &:active {
            background-color: rgba(var(--theme-color), 1);
            color: rgba(var(--white), 1);
        }
    }

    a {
        display:inline-block;
        width:49%;
    }
}

.copyright{
    font-size: 0.5rem !important;
    text-align:center;
}

.patient-btn, .patient-btn:hover{
    background-color:#f96a3f;
    color:#fff;
}
  
.patient-btn {
    &:active {
        background-color: #f96a3f !important;
        color: rgba(var(--white), 1) !important;
    }
}

.button-container {
    display: flex;
    justify-content: space-between;
    gap: 20px; /* Adjust the gap as needed */
}
  
