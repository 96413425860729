/*=============================
    Authentication CSS start
===============================*/
.login-img {
  width: 100%;
  object-fit: cover;
  &.auth-img {
    height: 186px;
  }
  &.restaurant-login-img {
    height: 100%;
    object-fit: cover;
  }
}

.line-vector {
  position: relative;
  left: 20px;
  height: 30px;

  [dir="rtl"] & {
    left: unset;
    right: 20px;
  }
}

h2 {
  font-size: 20px;
}

.division {
  position: relative;
  text-align: center;
  margin-top: calc(30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)));
  z-index: 0;

  span {
    background-color: rgba(var(--white), 1);
    color: rgba(103, 106, 109, 1);
    padding: 0 10px;
    font-weight: 600;
    font-size: 14px;
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    content: " ";
    width: 100%;
    background: linear-gradient(90deg, rgba(132, 132, 133, 0) 0%, rgba(132, 132, 133, 1) 50%, rgba(29, 16, 57, 0) 100%);
    z-index: -1;
    height: 2px;

    [dir="rtl"] & {
      left: unset;
      right: 0;
    }
  }
}

p {
  margin-top: 20px;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  color: rgba(var(--light-text), 1);
}

.otp-verification {
  h3 {
    color: rgba(var(--light-text), 1);
    margin-top: 45px;
    font-weight: 600;
  }

  .otp-number {
    color: rgba(var(--dark-text), 1);
    font-weight: 600;
  }
}

.location {
  position: relative;

  h2 {
    color: rgba(var(--dark-text), 1);
  }

  h5 {
    width: 100%;
    margin-top: 12px;
    font-size: 14px;
    line-height: 1.5;
    color: rgba(var(--light-text), 1);
  }

  .location-img {
    margin-top: 90px;
  }

  .animation-circle-inverse {
    z-index: 2;

    i {
      position: absolute;
      background: rgba(var(--theme-color), 1);
      box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11);
      border-radius: 100%;
      opacity: 0.4;
      animation: ripple1 3s linear infinite;
      top: 55%;
      left: 45%;
      z-index: 1;
      transform: translate(-50%);

      [dir="rtl"] & {
        left: unset;
        right: 45%;
      }

      &:nth-child(1) {
        height: 10px;
        width: 10px;
        animation: ripple1 3s linear infinite;
      }

      &:nth-child(2) {
        height: 20px;
        width: 20px;
        animation: ripple2 3s linear infinite;
      }

      &:nth-child(3) {
        height: 25px;
        width: 25px;
        animation: ripple3 3s linear infinite;
      }
    }
  }
}

// delivery boy app css
.line-design {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  li {
    width: 100%;
    height: 4px;
    background-color: rgba(var(--box-bg), 1);
    border-radius: 6px;
    &.active {
      background-color: rgba(var(--theme-color), 1);
    }
  }
}

.details-heading {
  margin-top: 20px;
  margin-bottom: 15px;
}

// .form-check {
//   width: 100%;
//   padding: 15px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   gap: 10px;
//   margin: 0;
//   background-color: rgba(var(--white), 1);
//   border: 1px solid rgba(var(--black), 0.05);
//   border-radius: 6px;

//   .form-check-label {
//     font-weight: 400;
//     font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
//     color: rgba(var(--dark-text), 1);
//   }

//   .form-check-input {
//     &:checked {
//       background-color: rgba(var(--theme-color), 1);
//       border-color: rgba(var(--theme-color), 1);
//     }

//     &:focus {
//       box-shadow: none;
//     }
//   }

//   .form-check-input:checked[type="radio"] {
//     background-image: url(../images/svg/check.svg);
//   }

//   .form-check-input {
//     &[type="radio"] {
//       width: 20px;
//       height: 20px;
//       border-radius: 100%;
//       margin: 0;
//     }
//   }
// }

.fixed-btn {
  position: fixed;
  width: 100%;
  max-width: 600px;
  padding: 20px 0;
  left: 50%;
  bottom: 0;
  transform: translate(-50%);
  z-index: 2;
  background-color: rgba(var(--white), 1);
}
