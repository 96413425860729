/*=============================
     Page-listing CSS start
===============================*/
.categories-title {
    margin-top: 20px;

    h2 {
        font-size: calc(16px + 2 * (100vw - 320px) / 1600);
        background-color: rgba(var(--box-bg), 1);
        padding: 15px;
        border-radius: 10px;
    }
}

.categories-menu {
    .navigation {
        .pages {
            width: 100%;
            padding: 0 10px;

            h4 {
                font-size: 15px;
            }

            a {
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: rgba(var(--dark-text), 1);
                font-size: calc(14px + 2 * (100vw - 320px) / 1600);
                font-weight: 500;
                width: 100%;

                i {
                    color: rgba(var(--dark-text), 1);
                    font-size: 30px;
                    line-height: 1;

                    [dir="rtl"] & {
                        transform: scaleX(-1);
                    }
                }
            }
        }
    }
}
