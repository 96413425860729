/*=====================
    Header CSS start
==========================*/

.header-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  a {
    z-index: 1;
  }

  i {
    color: rgba(var(--dark-text), 1);
    font-size: 25px;
  }

  .sidebar-btn {
    width: 35px;
    height: 35px;
    color: rgba(var(--theme-color), 1);
    padding: 5px;
    background-color: rgba(var(--theme-color), 0.1);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    z-index: 1;

    i {
      line-height: 1;
      font-size: 16px;
      color: rgba(var(--theme-color), 1);
    }
  }

  .calendar-icon {
    width: 35px;
    height: 35px;
    font-size: 20px;
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(var(--dark-text), 1);
    background-color: rgba(var(--box-bg), 1);
    border-radius: 6px;
    backdrop-filter: blur(2px);
  }

  h2 {
    position: absolute;
    font-weight: 600;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    line-height: 1;
    text-align: center;
    color: rgba(var(--dark-text), 1);
    font-size: 18px;
  }

  .create-address {
    color: rgba(var(--theme-color), 1);
  }

  .add-square-img {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    border: 1.5px solid rgba(var(--theme-color), 1);
    border-radius: 6px;
    width: 100%;
    padding: 0 5px;
    color: rgba(var(--theme-color), 1);
  }
  .icon {
    padding: 8px;
    font-size: 18px;
    background-color: rgba(var(--box-bg), 1);
    border-radius: 100%;
  }
}

.chatting-header {
  width: 100%;
  border: none;
  border-radius: unset;
  position: fixed;
  top: 0;
  left: 0;
  max-width: unset;
  transform: none;
  bottom: unset;

  .tracking-head {
    border-bottom: none;
    padding: 0;
    z-index: 1;

    .back-arrow {
      color: rgba(var(--dark-text), 1);
      font-size: 22px;
    }
  }
}
