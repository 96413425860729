/*=====================
    Loader CSS start
==========================*/
.loader-container {
  height: 100vh; /* Full viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-container-nav {
  height: 80vh; /* Full viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner-border {
  width: 2rem;
  height: 2rem;
  border-width: 0.2em; /* Adjust the border thickness */
  color: rgba(var(--theme-color), 1);
}
