/*========================
    Animation CSS start
==========================*/
@keyframes ripple1 {
  0% {
    transform: scale(5.5);
    opacity: 0.3;
  }
  100% {
    transform: scale(8.5);
    opacity: 0;
  }
}

@keyframes ripple2 {
  0% {
    transform: scale(3.5);
  }
  100% {
    transform: scale(5.5);
  }
}

@keyframes ripple3 {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(3.5);
  }
}

