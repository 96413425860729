.grocery-categories-box {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 8px 12px;
    color: rgba(var(--light-text), 1);
    background-color: rgba(var(--box-bg), 1);
    border-radius: 6px;
    [dir="rtl"] & {
        direction: rtl;
    }
    &.active {
        color: rgba(255, 255, 255, 1);
        background-color: rgba(var(--theme-color), 1);
        h6 {
            border-left: 1px solid rgba(255, 255, 255, 1);
            [dir="rtl"] & {
                border-right: 1px solid rgba(255, 255, 255, 1);
                border-left: unset;
            }
        }
    }

    h6 {
        padding-left: 8px;
        border-left: 1px solid rgba(var(--light-text), 0.4);
        [dir="rtl"] & {
            padding-right: 8px;
            padding-left: unset;
            border-right: 1px solid rgba(var(--light-text), 0.4);
            border-left: unset;
        }
    }
}
.grocery-banner-img {
    border-radius: 6px;
}
.grocery-product-box {
    position: relative;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid rgb(var(--box-bg), 1);
    background: rgba(var(--white), 1);
    box-shadow: 0px 2px 15px 0px rgba(var(--black), 0.04);
    [dir="rtl"] & {
        direction: rtl;
    }

    .offer-tag {
        position: absolute;
        top: 12px;
        left: 12px;
        padding: 5px 10px;
        color: rgb(var(--error-color), 1);
        background-color: rgb(var(--error-color), 0.15);
        border-radius: 10px;
        [dir="rtl"] & {
            right: 12px;
            left: unset;
        }
    }
    .grocery-product-img {
        padding: 48px 0 18px;
        text-align: center;
        .img {
            width: 90px;
            height: 70px;
            object-fit: contain;
        }
    }
    .grocery-product-details {
        h4 {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .price {
            font-size: 13px;
        }
    }
    .like-icon {
        position: absolute;
        top: 5px;
        right: 5px;

        [dir="rtl"] & {
            right: unset;
            left: 5px;
        }

        i {
            padding: 5px;
            color: rgba(var(--error-color), 1);
            background-color: rgba(var(--white), 1);
            border-radius: 100%;
            width: 26px;
            height: 26px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .fill-icon {
            display: none;
        }
        &.active {
            .outline-icon {
                display: none;
            }
            .fill-icon {
                display: flex;
                color: rgba(var(--error-color), 1);
            }
            .effect:before {
                animation: fireworkLine 0.5s linear 0.1s;
            }
            .effect:after {
                animation: fireworkPoint 0.5s linear 0.1s;
            }
        }
    }

    .effect-group {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .effect {
            display: block;
            position: absolute;
            top: 38%;
            left: 50%;
            width: 20px;
            transform-origin: 0px 2px;
            &:nth-child(2) {
                transform: rotate(72deg);
            }
            &:nth-child(3) {
                transform: rotate(72 * 2deg);
            }
            &:nth-child(4) {
                transform: rotate(72 * 3deg);
            }
            &:nth-child(5) {
                transform: rotate(72 * 4deg);
            }
            &:before {
                content: "";
                display: block;
                position: absolute;
                right: 0;
                border-radius: 2px;
                height: 3px;
                background: #777777;
            }
            &:after {
                content: "";
                display: block;
                position: absolute;
                top: 10px;
                right: 10%;
                border-radius: 50%;
                width: 3px;
                height: 3px;
                background: rgba(var(--theme-color), 1);
                transform: scale(0, 0);
            }
        }
    }
}

.grocery-horizontal-product-box {
    border-radius: 4px;
    border: 1px solid rgba(var(--box-bg), 1);
    background: rgba(var(--white), 1);
    box-shadow: 0px 2px 15px 0px rgba(var(--white), 0.04);
    padding: 12px;

    li {
        padding: 20px 0;
        display: flex;
        align-items: center;
        gap: 6px;
        border-bottom: 1px solid rgba(var(--box-bg), 1);
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            padding-bottom: 0;
            border-bottom: none;
        }
        .horizontal-product-img {
            .img {
                width: 64px;
                height: 64px;
            }
        }

        .horizontal-product-details {
            position: relative;
            width: calc(100% - 70px);
            .add-btn {
                position: absolute;
                right: 0;
                bottom: 0;
                padding: 5px calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
                font-size: 13px;
                color: rgba(var(--theme-color), 1);
                background-color: rgba(var(--theme-color), 0.1);
                border-radius: 5px;
                [dir="rtl"] & {
                    right: unset;
                    left: 0;
                }
            }
        }
    }
}

.grocery-horizontal-product-box2 {
    border-radius: 4px;
    border: 1px solid rgba(var(--box-bg), 1);
    background: rgba(var(--white), 1);
    box-shadow: 0px 2px 15px 0px rgba(var(--black), 0.04);
    padding: 12px;
    display: flex;
    align-items: center;
    gap: 12px;

    .horizontal-product-img {
        .img {
            background-color: rgba(var(--box-bg), 1);
            width: 64px;
            height: 64px;
            padding: 8px;
        }
    }

    .horizontal-product-details {
        position: relative;
        width: calc(100% - 70px);
        h5 {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        h6 {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            line-height: 1;
        }
        .add-btn {
            position: absolute;
            right: 0;
            bottom: 0;
            padding: 5px calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
            font-size: 13px;
            color: rgba(var(--theme-color), 1);
            background-color: rgba(var(--theme-color), 0.1);
            border-radius: 5px;
            [dir="rtl"] & {
                right: unset;
                left: 0;
            }
        }
    }
}
